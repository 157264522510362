import { PersistentContext } from "src/contexts/AuthContext/PersistentContext";
import { Section } from "./NavEngineFunctions";
import {
  HomeIcon,
  SubscriptionsIcon,
  MembersIcon,
  MemoriesIcon,
  DocumentsIcon,

  FuneralsIcon,
  CredentialsIcon,
  DirectivesIcon,
  TestamentIcon,

  SavingsIcon,
  HealthIcon,
  PotIcon,
  TimelapseIcon,

  NotificationIcon,
}from './NavIcon';

import { SpaceType } from "src/contexts/AuthContext/Space";
import {MemberType} from "../../../api/wishbook/users/model";

export const buildNavigation = (context: PersistentContext, t): Section[] => {

  if (context.getCurrentSpace().type == SpaceType.CLASSIC) {
    const isProfessionalGuardian = context.isNotMe() && context.mySpace.wishbookers.length > 0 && context.isMemberProfessional()

    if(isProfessionalGuardian) {
      return [{
        subheader: t('navbar_section_1'),
        items: [
          {
            title: t('navbar_professional_documents'),
            icon: DocumentsIcon,
            href: '/classic/professional-documents',
          }
        ]
      }];
    }

    return [{
      subheader: t('navbar_section_0'),
      items: [
        {
          title: t('navbar_dashboard'),
          icon: HomeIcon,
          href: '/classic',
        },
        {
          title: t('navbar_guardians'),
          icon: MembersIcon,
          //info: context.mySpace.shouldReachMembers ? NotificationIcon : undefined,
          href: '/classic/members',
        },
        {
          title: t('navbar_subscriptions'),
          icon: SubscriptionsIcon,
          href: '/classic/subscriptions',
        },
      ]
    },
    {
      subheader: t('navbar_section_1'),
      items: [
        {
          title: t('navbar_memories'),
          icon: MemoriesIcon,
          href: '/classic/memories/me',
        },
        {
          title: t('navbar_funerals'),
          icon: FuneralsIcon,
          href: '/classic/funerals',
        },
        {
          title: t('navbar_credentials'),
          icon: CredentialsIcon,
          href: '/classic/credentials',
        },
        {
          title: t('navbar_directives'),
          icon: DirectivesIcon,
          href: '/classic/directives',
        },
        {
          title: t('navbar_testament'),
          icon: TestamentIcon,
          href: '/classic/testament',
        },
        {
          title: t('navbar_documents'),
          icon: DocumentsIcon,
          href: '/classic/documents',
        },
        {
          title: t('navbar_professional_documents'),
          icon: DocumentsIcon,
          href: '/classic/professional-documents',
        }
      ]
    }];
  }
  else if (context.getCurrentSpace().type == SpaceType.KIDS) {
    return [{
      subheader: "KIDS",
      items: [
        {
          title: t('navbar_dashboard'),
          icon: HomeIcon,
          href: '/kids',
        },
        {
          title: t('navbar_ff'),
          icon: MembersIcon,
          href: '/kids/members',
        },
        {
          title: t('navbar_subscriptions'),
          icon: SubscriptionsIcon,
          href: '/kids/subscriptions',
        },
      ]
    },
    {
      subheader: t('navbar_section_1'),
      items: [
        {
          title: t('navbar_albums'),
          icon: MemoriesIcon,
          href: '/kids/albums',
        },
        {
          title: t('navbar_documents'),
          icon: DocumentsIcon,
          href: '/kids/documents',
        },
        {
          title: t('navbar_timelapse'),
          icon: TimelapseIcon,
          href: '/kids/timelapse',
        },
        {
          title: t('navbar_savings'),
          icon: SavingsIcon,
          href: '/kids/savings',
        },
        {
          title: t('navbar_pot'),
          icon: PotIcon,
          href: '/kids/pot',
        },
        {
          title: t('navbar_health'),
          icon: HealthIcon,
          href: '/kids/health',
        }
      ]
    }];
  }
  return [];
};

export default buildNavigation;
